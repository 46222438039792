import React, { useEffect } from "react";
import appHomeScreen from "./Frame.jpg";
import "./appHomeScreen.scss";
import { Button } from "react-bootstrap";
import AppSigin from "./common/appScreen/appSigin";
import { OrderFlow } from "./App";
import { Helmet } from "react-helmet";
import { useNavigate,useLocation } from "react-router-dom";
const HomeScreen = () => {
    const { setAppLoginPopupShow, setCurrentPage } = OrderFlow();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const handleAppLogin = () => {
        setAppLoginPopupShow(true);
        setCurrentPage("signIn");
    }
    const loginCheck1 = localStorage.getItem("isLoggedIn");
    const loginCheck = loginCheck1 === "true";
    const AppBannerDate = localStorage.getItem("appBanner") ? JSON.parse(localStorage.getItem("appBanner")) : '';
    const handleSkip = () => {
        const ClientPath = localStorage.getItem("path");
        if(ClientPath){
        navigate(`/${ClientPath}/menu`);
        }
        else{
        navigate('/');
        }
    }
    const width = window.innerWidth;

    return (
        <>
            {pathname.endsWith("/app") &&
                <Helmet>
                    <style type="text/css">{`
                   @media only screen and (min-width: 320px) and (max-width: 767px) {
                    nav.main-header {
                      display: none;
                    }
                   .checkbox-wrapper-54 .slider {
                      display:none;
                    }
                   .feedback{
                    display: none;
                   }
                   .checkbox-wrapper-54 input:checked + .slider {
                     display:none;
                    }
                     footer {
                     display:none;
                    }
                }
                        `}</style>
                </Helmet>
            }

            {width < 767 && (
                <>
                    <div className="homescreen-app" >
                        <div className="homescreen-app-top">
                            <div className="homescreen-app-skip" onClick={handleSkip}>Skip</div>
                            <img src={appHomeScreen} className="homescreen-app-img" alt="homescreen-app-img" title="homescreen-app-img" />
                        </div>
                        <div className="homescreen-app-content">
                            <div className="homescreen-app-text">{AppBannerDate.content ? AppBannerDate.content : "Get started with Fusion"}</div>
                            <Button className="homescreen-app-button" onClick={handleAppLogin}>Continue</Button>
                        </div>
                    </div>
                    <AppSigin />
                </>)
            }
        </>
    )
}
export default HomeScreen;